@import "./vendors/HelloAsso";

input.ui-autocomplete-input {
  min-width: 250px;

  @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
    min-width: 0;
  }
}

.ui-widget-overlay {
  background: #666;
}

.ui-dialog {
  font-size: 0.9em;

  .ui-widget-header {
    background: #fff;
    border: none;
    border-bottom: 1px solid #ccc;
  }
}

.ui-dialog-titlebar-close.ui-state-hover {
  background: none;
  border: none;
}

.ui-icon {
  &.ui-icon-closethick {
    background-image: none;
    position: relative;

    &:before {
      @extend %fa-icon;
      @extend .fa-solid;
      content: fa-content($fa-var-times);

      top: 0;
      left: 0;
      position: absolute;
      text-indent: 0;
    }
  }
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  font-weight: normal;
  border: none;
  color: #23a186;
}

.ui-autocomplete {
  z-index: 9999999;
}

body .tox .tox-editor-header {
  z-index: 1;
}
