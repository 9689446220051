/**
 * Ce fichier écrase les surcharges
**/

/**
 * Les surcharges bootstrap
**/
a {
  text-decoration: none;
}

em {
  font-style: italic;
}

ul {
  padding-left: 0;
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 1.7rem;
  }
  h2,
  .h2 {
    font-size: 1.2rem;
  }
}

.alert p {
  margin-bottom: 0;
}

/**
 * Les surcharges Heva
**/
a.btn {
  &:visited {
    color: var(--bs-btn-color);
  }

  &:hover {
    border-radius: var(--bs-border-radius);
  }
}

a.button.delete:hover {
  color: color-contrast($danger);
}

.season a.delete {
  background-image: none;
  padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
}

#content ul.list-group {
  margin: 0;
}

.flash_notice {
  @extend .alert;
  @extend .alert-warning;
  font-weight: normal;
  text-shadow: none;
}

.flash_success {
  @extend .alert;
  @extend .alert-success;
  font-weight: normal;
  text-shadow: none;
}

.flash_error {
  @extend .alert;
  @extend .alert-danger;
  font-weight: normal;
  text-shadow: none;
}

.error_list li {
  @extend .badge;
  @extend .text-bg-danger;

  text-shadow: none;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  border-radius: var(--bs-badge-border-radius);
}

#main_menu li span {
  width: auto;
  height: auto;
  margin-left: 0;
  background: none;
  float: none;

  top: 14%;
  right: -7px;
}
