// Pour chaque grid_1 à grid_12 étendre le col col-md-1 à col-md-12 correspondant
@for $i from 1 through $grid-columns {
    .grid_#{$i} {
        @include media-breakpoint-up(sm) {
            @include make-col($i, $grid-columns);
        }
    }
}

.sidebar-wrapper {
    .pinned_top {
        @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
            position: initial !important;
        }
    }
}
