html, body {
    background: linear-gradient(145deg, #2e485a, #0b1014);
    height: 100vh;
}

body#login {
    display: flex;
    flex-direction: column;

    &> .container {
        flex: 1;
    }

    #header {
        background-color: transparent!important;
        background-image: none!important;
        padding-top: 8vh;
        position: relative;

        @media screen and (max-width: 1200px) {
            padding-top: 5vh;
        }
    }

    #logo {
        text-align: center;
    }

    #header .content,
    #content {
        background: transparent;
        width: 100%;
        height: 100%;
    }

    #content {
        padding: 15px 0 30px;
    }

    table td {
        padding: 0;
    }

    form {
        tr {
            height: 50px;
        }
        th {
            text-align: right;
        }
    }

    p {
        margin: 0;
        line-height: 1.25em;
        font-size: 0.85em;
        font-size: .7em;
    }

    #extra-footer {
        background: none;
        border-top: 1px solid #292929;
        padding: 20px 10px;

        img {
            margin: -10px 0;
        }
    }

    label {
        width: 100%;
    }

    input {
        max-width: none;
    }
}

#homepage {
    .center {
        background: white;
        padding: 30px;
        border-radius: 10px;
        margin-bottom: 30px;
    }

    h1 {
        text-align: center;
        color: #fff;
        font-size: 2em;
    }

    button[type="submit"] {
        --bs-btn-border-color: #20314a;
    }
}