.HaAuthorizeButton {
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  background-color: #ffffff;
  border: 0.0625rem solid #4b3fcf;
  border-radius: 0.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.HaAuthorizeButton:disabled {
  background-color: #4b3fcf;
  border-color: transparent;
  cursor: not-allowed;
}
.HaAuthorizeButton:not(:disabled):focus {
  box-shadow: 0 0 0 0.25rem rgba(73, 211, 138, 0.25);
  -webkit-box-shadow: 0 0 0 0.25rem rgba(73, 211, 138, 0.25);
}
.HaAuthorizeButtonLogo {
  padding: 0 0.8rem;
  width: 2.25rem;
}
.HaAuthorizeButtonTitle {
  background-color: #4b3fcf;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.78125rem 1.5rem;
}
.HaAuthorizeButton:disabled .HaAuthorizeButtonTitle {
  background-color: #4b3fcf;
  color: #9a9da8;
}
.HaAuthorizeButton:not(:disabled):hover .HaAuthorizeButtonTitle,
.HaAuthorizeButton:not(:disabled):focus .HaAuthorizeButtonTitle {
  background-color: #4b3fcf;
}
