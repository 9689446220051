// Reprends node_modules/bootstrap/scss/_buttons.scss
// En l'appliquant à tous nos boutons

button,
a.button {
    @extend .btn;

    // Ajouts spécifiques Heva
    &, &:hover {
      background-image: none;
      text-shadow: none;
    }

    &[type="submit"]:not(.btn-success) {
      @include button-variant($primary, $primary-border-subtle);
    }

    &.delete,
    &.cancel {
      @include button-variant($danger, $danger-border-subtle);
    }
}

a.add,
a.edit,
a.delete,
a.renew {
    @extend .btn;
    @extend .btn-sm;

    background-image: none;

    &:before {
        @extend %fa-icon;
        @extend .fa-solid;
        margin-right: calc(var(--bs-btn-padding-x) - 5px);
        margin-left: 5px;
    }

    &.btn-lg {
        @extend .btn-lg;
    }

    &.btn-md {
        @include button-size(.37rem, .37rem, .37rem, .37rem);
    }
}

a.add {
    @include button-variant($primary, $primary-border-subtle);

    &:before {
        content: fa-content($fa-var-plus);
    }
}

a.edit {
    @include button-variant($light, $light-border-subtle);

    &:before {
        content: fa-content($fa-var-pencil);
    }
}

a.delete {
    @include button-variant($danger, $danger-border-subtle);

    &:before {
        content: fa-content($fa-var-trash);
    }
}

a.renew {
    @include button-variant($primary, $primary-border-subtle);

    &:before {
        content: fa-content($fa-var-arrows-rotate);
    }

    span {
        display: none;

        @include media-breakpoint-up(sm) {
            display: inline-block;
        }
    }
}

.list .action a.edit,
.season a {
    @extend .btn-sm;
}