@import "~bootstrap/scss/bootstrap";
@import "~bootstrap4-toggle/css/bootstrap4-toggle.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

// $fa-font-path: "/web/build/fonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

@import "_variables.scss";
@import "_reboot.scss";
@import "_buttons.scss";
@import "_borders.scss";
@import "_grid.scss";
@import "_widgets.scss";
@import "_forms.scss";
@import "header.scss";
@import "footer.scss";
@import "homepage.scss";
@import "login.scss";
@import "admin.scss";
@import "sfMediaBrowser.scss";

body {
  font-family: "Roboto Condensed", sans-serif;
}

.pinned_top {
  margin-top: 60px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

#content_landscape {
  position: absolute;
  width: 100%;
  height: 20vh;
  background: linear-gradient(327deg, #23a1862b, #23a18645);
  margin-top: -20px;
}

#player-extended-form th {
  width: 25%;
}
#player-extended-form #mail_checkboxes th {
  width: auto;
}

.card {
  table.list {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.affiliation-prices tbody th {
      color: var(--bs-gray-900);
      font-weight: normal;
    }
  }
}

body .container_12 {
  @extend .container;

  @include media-breakpoint-up(xl) {
    width: 100%;
  }
}

.sidebar-wrapper {
  @extend .col-sm-12;
  @extend .col-md-3;
}

table.list td.PlayerNeedValidation,
table.list td.PlayerWaitForPayment {
  background: none;

  a:before {
    @extend %fa-icon;
    @extend .fa-solid;
    content: fa-content($fa-var-clock);
    color: #000;
  }
}

// Sur les mobiles, les select dans des formulaires en tableau
// Ne peuvent pas faire + de la moitié de l'écran en largeur
@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  table tr th + td select {
    max-width: 65vw;
  }
}

// Faire en sorte que les ancres (http://url/page#ancre) soient positionnée en dessous du header
h1,
h2,
h3,
h4 {
  &[id]::before {
    content: "";
    display: block;
    height: 100px;
    margin-top: -100px;
    visibility: hidden;
  }
}
