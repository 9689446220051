form {
  // Mettre un peu d'espace entre les sections des formulaires
  h2 {
    margin-top: 1em;
  }

  // Aligner en haut des cellules les labels
  table th {
    vertical-align: top;
    padding-top: 6px;
  }

  @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
    table th {
      max-width: 35vw;
    }
  }
}

// Ecraser les contradictions heva main.css & bootstrap
.form-group {
  padding: 5px 0;

  input {
    max-width: 100%;
  }
}

/**
 * Fix le problème du datepicker affiché après un input text
 */
.input-group img.ui-datepicker-trigger {
  padding: 7px 10px;
  border: 1px solid #ccc;
  border-radius: 0 3px 3px 0;
  margin: -5px 0 5px -1px;
}

td:has(.input-group-date) {
  .input-group-date {
    margin-bottom: 0 !important;
  }
  br {
    display: none;
  }
  margin-bottom: 1rem;
}

input[type="checkbox"].form-check-input,
input[type="radio"].form-check-input {
  background-color: var(--bs-form-check-bg) !important;
  background-image: var(--bs-form-check-bg-image) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;

  &:checked {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

#dialog_content form,
#content .grid_8 form,
#content .grid_9 form {
  table {
    td {
      padding: 0.2em 0;
    }
  }

  textarea,
  input[type="text"],
  input[type="number"],
  input[type="date"],
  select {
    @extend .form-control;

    max-width: 60%;

    &.size_1,
    &.size_2,
    &.size_3 {
      max-width: 100%;
    }

    @media screen and (max-width: map-get($grid-breakpoints, "md")) {
      max-width: 100%;
    }
  }

  select {
    @extend .form-select;
  }
}

.form-text {
  margin-bottom: 0.7em;
}

// Modal modification/prise de licence / ...
form .address-form > ul.address-block > li {
  margin-bottom: 0.5rem !important;

  &.postal-code {
    width: 80px;
    margin-right: 5px;
  }

  &.distribution {
    width: 100%;
  }
}

.person-contact-representative {
  h3 {
    margin: 3px 0;
  }
}
