#sf_media_browser_list > li.placeholder:only-child {
  position: relative;
  opacity: 1;
  background: #c1c1c1;
  text-align: center;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 17px;

  &:before {
    @extend %fa-icon;
    @extend .fa-solid;
    content: fa-content($fa-var-inbox);

    top: 50%;
    left: 50%;
    position: absolute;
    text-indent: 0;
    font-size: 2em;
    color: #000;
    opacity: 1;
    margin-top: -0.8em;
    margin-left: -0.5em;
  }
}
