#homepage-shortcuts a:hover {
    background-color: transparent;
}

#homepage .articles {
    h3,
    h4 {
        margin-top: 0;
        padding-left: 0;
    }

    .card-title {
        border-bottom: 1px solid #ccc;
    }

    .article {
        margin-bottom: 10px;
    }
}