:root {
  --bs-body-font-size: .9em;
  --bs-btn-font-size: 1rem;
}

$white: #fff;
$black: #000;
$blue: #2ea5de;

$green-fnsmr: #cae379;
$green-fnsmr-primary: #19a186;
$green-fnsmr-secondary: #98b964;

$grey-fnsmr-primary: #4d555f;
$grey-fnsmr-secondary: #2d343a;