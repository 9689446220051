body #header {
  height: auto;
  position: fixed;
  z-index: 2;
  width: 100%;
  background-color: $green-fnsmr-primary;
  background-image: linear-gradient(
    270deg,
    $green-fnsmr-secondary 30%,
    $green-fnsmr-primary 45%
  );

  .association {
    position: initial;
    font-size: 14px;
    text-align: left;
    padding: 7px 0 0 15px;
    border-left: 1px solid $gray-600;
    text-shadow: none;

    @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
      padding: 5px 0 0 0px;
      border-left: none;
    }

    a {
      color: $black;
      text-decoration: none;
    }
  }
}

#container-logo,
#container-logo-fnsmr {
  @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    width: 140px;
  }

  .logo {
    position: initial;
  }

  a {
    &:hover {
      background: none;
    }
  }
}

#container-logo-fnsmr {
  @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    width: 200px;
  }
}

#container-logo-fnsmr {
  border-left: 1px solid $gray-600;
}

#container-logo,
#container-logo-fnsmr {
  a {
    display: flex;
    height: 100%;
  }
}

#navbar-global-header {
  margin: 0;
  padding: 0;
  background-image: linear-gradient(
    270deg,
    $green-fnsmr-secondary 30%,
    $green-fnsmr-primary 45%
  );
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
    background-image: none;
  }
}

#header {
  @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
    background: linear-gradient(
      $grey-fnsmr-primary,
      $grey-fnsmr-secondary
    ) !important;
  }

  #container-my-account-and-cart {
    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
      min-width: 313px;
    }

    .shopping-status {
      min-width: 215px;
      font-size: 12px;

      background: $white;
      background-image: linear-gradient(45deg, white, #e9e9e9);
      border-radius: 0 0 5px 5px;

      &:before {
        @extend %fa-icon;
        @extend .fa-solid;
        content: fa-content($fa-var-cart-shopping);

        margin: 10px 10px 0 -20px;
        color: $blue;
        font-size: 1.3em;
      }

      span.count {
        font-family: inherit;
      }
    }
  }

  .account {
    padding-top: 7px;

    a {
      color: $black;
      text-shadow: none;
    }

    a:hover,
    .logout:hover {
      background-color: transparent !important;
      border-bottom: 1px solid;
      color: grey !important;
    }
  }

  .container-fluid {
    .logo {
      position: initial;
    }
  }
}

#bottom-header {
  #main_menu {
    padding: 0;

    .nav-item {
      @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
        height: auto;
        display: flex;
        flex-direction: column;

        a {
          border-bottom: 1px solid $white;
          border-radius: 0;
          margin-top: 5px;
          border-radius: 5px;
        }

        &:last-child {
          a {
            border-bottom: none;
          }
        }
      }

      .sub_menu {
        @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
          display: block !important;
          position: initial;
          margin-left: 20px;
          background: transparent;
          border: none;

          li {
            border: none;

            a {
              background-color: transparent;
              margin-top: 0;
              border: none;
            }

            &:last-child {
              border-radius: 0 0 6px 6px;
            }
          }
        }
      }
    }

    & > li {
      height: 100%;
    }

    li {
      a {
        color: $white;
        border-radius: 0;
        padding: 11px 12px;

        &:hover {
          background: rgba(255, 255, 255, 0.2);
          box-shadow: none;
          text-shadow: none;
        }
      }

      ul.sub_menu {
        top: 46px;
        background: $green-fnsmr-primary;
        border: 1px solid $white;

        li {
          border-color: #1b9077;
          background-color: transparent;

          &:hover {
            background-color: $green-fnsmr-secondary;
          }

          &:last-child {
            border-bottom: none;

            &:hover,
            a,
            a:hover {
              border-radius: 0 0 6px 6px;
            }
          }

          @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
            border-color: $white;
          }

          a {
            width: 100%;
            background-color: $green-fnsmr-primary;

            &:hover {
              background-color: $green-fnsmr-secondary;
            }

            @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
              background-color: transparent;
              font-weight: initial;
            }
          }
        }
      }
    }
  }

  #container-search-menu {
    @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
      margin-top: 10px;
    }
  }

  #search {
    padding-right: 5px;

    input[type="text"] {
      width: 300px;

      @media screen and (max-width: map-get($grid-breakpoints, "xl")) {
        width: 200px;
      }
    }
  }
}

#content {
  top: 82px;
  position: relative;

  @media screen and (min-width: map-get($grid-breakpoints, "sm")) and (max-width: map-get($grid-breakpoints, "lg")) {
    top: 35px;
  }

  @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
    top: 65px;
  }

  &.iframe {
    top: 0;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "xl")) {
  #main_menu li a {
    font-size: 15px;
    padding: 8px 12px;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "lg")) {
  #bottom-header {
    flex-direction: column;
  }
}
